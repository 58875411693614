import React from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ConfigProvider locale={zhCN} theme={{
            components: {
                Layout: {
                    bodyBg: '#F1F3F4',
                    headerBg: '#fff',
                    footerBg: '#fff',
                    headerColor: '#333',
                    siderBg: '#000c18'
                },
            }
        }}>
            <HashRouter>
                <App />
            </HashRouter>
        </ConfigProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
