import axios from 'axios';

const baseURL = 'https://shopping.test.huanjiangren.com'

const API = {
    // 登录
    login: {
        url: '/admin_api/auth/login',
        method: 'post'
    },
    // 退出登录
    logout: {
        url: '/admin_api/auth/logout',
        method: 'get'
    },



    // 商品管理
    getGoodses: {
        url: '/admin_api/goodses',
        method: 'get'
    },

    addGood: {
        url: '/admin_api/goodses',
        method: 'post'
    },
    // const params = {
    //     "category_id": 1,
    //     "store_id": 1,
    //     "title": "测试",
    //     "thumb": "http://www.xx.com/xx.jpg",
    //     "banner": [
    //         "http://www.xx.com/xx.jpg",
    //         "http://www.xx.com/xx.mp4"
    //     ],
    //     "params": [
    //         {
    //             "key": "参数名",
    //             "val": "参数值"
    //         }
    //     ],
    //     "show": "1.内含弹射结构",
    //     "imgs": [
    //         "http://www.xx.com/xx.jpg",
    //         "http://www.xx.com/xx.jpg"
    //     ],
    //     "order": 1,
    //     "status": 1
    // }

    updateGood: {
        getUrl:  function(params) {
            return `/admin_api/goodses/${params}`
        },
        method: 'put'
    },

    updateGoodStatus: {
        getUrl: function(params) {
            return `/admin_api/goodses/${params}/status`
        },
        method: 'put'
    },

    updateGoodAttr: {
        getUrl: (params) => {
            return `/admin_api/goodses/${params}/update_attrs`
        },
        method: 'put'
    },

    updateSku: {
        getUrl: (params) => {
            return `/admin_api/goodses/${params}/update_sku`
        },
        method: 'put'
    },

    // 意见反馈
    getFeedback: {
        url: '/admin_api/feedbacks',
        method: 'get'
    },



    // 类目管理
    getCategories: {
        url: '/admin_api/categories',
        method: 'get'
    },
    addCategorie: {
        url: '/admin_api/categories',
        method: 'post'
    },
    updateCategorie: {
        url: '/admin_api/categories',
        method: 'put'
    },
    delCategorie: {
        url: '/admin_api/categories',
        method: 'delete'
    },

    // 订单管理
    getOrders: {
        url: '/admin_api/orders',
        method: 'get'
    },

    updateOrderStatus: {
        getUrl: (params) => {
            return `/admin_api/orders/${params}`
        },
        method: 'put'
    },

    // 上传图片
    uploadPic: {
        url: baseURL + '/admin_api/upload/pic',
        method: 'post'
    }
}


const request = (config) => {
    let url, method = 'get';
    if (typeof config.url === 'string') {
        url = config.url;
        method = config.method || 'get';
    } else {
        const conf = config.url;
        if (conf.getUrl) {
            url = conf.getUrl(config.urlParamStr);
            delete config.urlParamStr;
        } else {
            url = conf.url;
        }
        method = conf.method
    }
    return axios({
        headers: {
            Authorization: localStorage.getItem('token')
        },
        baseURL,
        ...config,
        url,
        method
    })
}

const deleteFromList = (list, index, count = 1) => {
    let newList = list || [];
    newList.splice(index, count);
    return newList
}

const updateFromList = (list, index, item) => {
    let newList = list || [];
    newList.splice(index, 1, item);
    return newList
}

export {
    API,
    request,
    deleteFromList,
    updateFromList
}
