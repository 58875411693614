import React from 'react';
import { Layout, Button, Select, Row, Col, Form, Input, Popover, Modal, message, DatePicker, Table } from 'antd';
import dayjs from 'dayjs';
import { request, API } from '../../utils';

import './index.css';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { confirm } = Modal;
const { Item } = Form;
const { getOrders, updateOrderStatus } = API;

const statusMap = {
    0: {
        txt: '订单已关闭',
        color: '#666',
    },
    1: {
        txt: '待支付',
        color: '#EF2F2F',
    },
    2: {
        txt: '仓库拣货中',
        color: '#FFA800'
    },
    3: {
        txt: '配送员送货中',
        color: '#FFA800'
    },
    4: {
        txt: '换货申请中',
        color: '#1677FF'
    },
    5: {
        txt: '换新货送货中',
        color: '#1677FF'
    },
    6: {
        txt: '交易完成',
        color: '#0BC22A'
    },
    7: {
        txt: '商品已送达',
        color: '#0BC22A'
    }
};

class Category extends React.Component {
    state = {
        searchParam: {
            page: 1,
            page_size: 5,
            status: '-1',
            order_num: '',
            spu_name: '',
            address_phone: '',
            address_username: '',
            start: '',
            end: '',
        },
        originSearchParam: {
            page: 1,
            page_size: 5,
            status: '-1',
            order_num: '',
            spu_name: '',
            address_phone: '',
            address_username: '',
            start: '',
            end: '',
        },
        currentOrderInfo: {},
        currentOrderSkuList: []
    }

    componentDidMount() {
        this.getOrderData();
    }

    getOrderData = (params = {}) => {
        message.loading();
        const { searchParam } = this.state;
        const mergeParams = {
            ...searchParam,
            ...params
        }
        this.setState({
            searchParam: {
                ...mergeParams
            }
        })
        request({
            url: getOrders,
            params: mergeParams
        }).then(res => {
            const data = res.data || {};
            if (data.code === 200) {
                const dataObj = data.data || {};
                const { total = 0, current_page = 1 } = dataObj;
                const list = dataObj.data || [];

                let newList = [];
                let order = {};
                list.forEach(order => {
                    const { price_sum, order_num,
                        skus = [], status, id, created_at,
                        address, pay_id, pay_time, pay_type = '微信支付'
                    } = order || {};
                    order = {
                        address,
                        skus,
                        price_sum,
                        status,
                        id,
                        order_num,
                        created_at,
                        pay_id,
                        pay_time,
                        pay_type,
                        rowSpan: skus.length
                    };
                    order.skus = [];
                    skus.forEach((sku, i) => {
                        const { size, price, goods_info, id } = sku || {};
                        const { attr_name, goods, attr_img, sku_num } = goods_info || {};
                        const { title } = goods || {};
                        order.skus.push({
                            id,
                            sku_num,
                            status,
                            size,
                            price,
                            title,
                            attr_name,
                            attr_img
                        });
                    });
                    newList.push(order);
                    order = null;
                });


                this.setState({
                    data: newList,
                    total,
                    current_page
                });
            }
            message.destroy();

        }).catch(err => {
            message.error(err.message);
        })
    }

    pageChange = (page) => {
        this.getOrderData({
            page
        });
    }

    search = (params) => {
        const date = params.date;
        let start = '', end = '';
        if (date) {
            start = dayjs(date[0]).format('YYYY-MM-DD');
            end = dayjs(date[0]).format('YYYY-MM-DD');
        }
        const {
            address_username,
            order_num,
            spu_name,
            status,
            address_phone
        } = params;

        this.getOrderData({
            start,
            end,
            spu_name,
            order_num,
            address_username,
            status,
            address_phone
        })
    }

    changeOrderStatus(data) {
        confirm({
            title: `确认发货`,
            content: <>
                确定订单中的所有商品已经准备发货吗？
            </>,
            onOk: () => {
                message.loading();
                request({
                    url: updateOrderStatus,
                    urlParamStr: data.id,
                    data: {
                        status: 3
                    }
                }).then(res => {
                    const { code, data } = res.data || {};
                    if (code === 200) {
                        message.success('发送成功');
                        this.getOrderData();
                    }
                    setTimeout(() => {
                        message.destroy();
                    }, 2000);
                });
            }
        })
    }

    showOrderModal(data) {
        const { address: addressInfo, order_num, price_sum, created_at, skus, pay_id, pay_time } = data || {};
        const { name, house_num, phone, address, user_id } = addressInfo || {}

        const addressTxt = `${name}，${phone}，${address}，${house_num}`;


        const currentOrderSkuList = skus.map((sku, index) => {
            const { attr_img, attr_name, sku_num, price, size, id, title, status } = sku;
            return {
                id,
                sku_num,
                attr_img,
                attr_name,
                title,
                price,
                size,
                status,
                rowSpan: index === 0 ? skus.length : 0
            }
        });

        this.setState({
            orderInfoModalVisible: true,
            currentOrderSkuList,
            currentOrderInfo: {
                order_num,
                create_time: created_at,
                address: addressTxt,
                price_sum,
                user_id,
                pay_id,
                pay_time,
                pay_type: '微信支付'
            }
        })
    }

    render() {
        const columns = [
            {
                title: '操作',
                dataIndex: 'status',
                width: 70,
                render: (status, data) => (
                    <Row>
                        {
                            (status === 2 || status === 4) && (
                                <Col>
                                    <Button type='link' onClick={() => this.changeOrderStatus(data)}>确认发货</Button>
                                </Col>
                            )
                        }
                        <Col>
                            <Button type='link' onClick={() => this.showOrderModal(data)}>查看</Button>
                        </Col>
                    </Row>
                )
            },
            // {
            //     title: '预计送达时间',
            //     dataIndex: 'age',
            // },
            {
                title: '订单状态',
                dataIndex: 'status',

                width: 130,
                render: (status) => (
                    <span style={{ color: statusMap[status].color, fontWeight: 'bold' }}>{statusMap[status].txt}</span>
                )
            },
            {
                title: '商品信息',
                dataIndex: 'goodsInfo',
                render: (info, data) => {
                    const skus = data.skus || [];
                    return (skus.map(sku => (
                        <Row key={sku.id} className='skuBox' style={{ width: 320 }}>
                            <Col style={{ width: 68, height: 68, marginRight: 10, marginBottom: 3, marginTop: 3 }}>
                                <div className='attrImg' style={{ backgroundImage: `url(${sku.attr_img})` }}></div>
                            </Col>
                            <Col style={{ width: 210, height: 68 }} align="">
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col style={{ width: 210 }}>
                                                <Popover content={(
                                                    <div className='popOverContent'>
                                                        {sku.title}
                                                    </div>
                                                )}>
                                                    <div className='twoLine'>
                                                        {sku.title}
                                                    </div>
                                                </Popover>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='sku'>
                                        {sku.attr_name}
                                    </Col>
                                </Row>
                            </Col>

                            <Col className='sizeBox' style={{ width: 24 }}>
                                x{sku.size}
                            </Col>
                        </Row>
                    ))
                    )
                }
            },
            {
                title: '销售单价',
                dataIndex: 'skus',
                render: (skus) => (
                    skus.map(sku => (
                        <Row key={sku.id}>
                            <Col style={{ height: 74}}>
                                {sku.price}
                            </Col>
                        </Row>
                    ))
                )
            },
            {
                title: '下单时间',
                dataIndex: 'created_at',
            },

            {
                title: '订单总金额',
                dataIndex: 'price_sum',
            },
            {
                title: '收货人信息',
                dataIndex: 'address',
                render: ({ name, address, phone, house_num } = {}) => (
                    `${name}，${phone}，${address}，${house_num}`
                ),
            },
            {
                title: '收货人id',
                dataIndex: 'address',
                render: (address) => (
                    <span>{address.user_id}</span>
                )
            },

            {
                title: '订单号',
                dataIndex: 'order_num',

            },
            
        ];

        const orderInfoColumns = [
            {
                title: 'sku编码',
                dataIndex: 'sku_num',

            },
            {
                title: '商品信息',
                dataIndex: 'title',
                render: (title, info) => {
                    const { attr_img, attr_name, size } = info || {};
                    return (
                        <Row className='skuBox' style={{ width: 320 }}>
                            <Col style={{ width: 68, height: 68, marginRight: 10, marginBottom: 3, marginTop: 3 }}>
                                <div className='attrImg' style={{ backgroundImage: `url(${attr_img})` }}></div>
                            </Col>
                            <Col style={{ width: 210, height: 68 }} align="">
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col style={{ width: 210 }}>
                                                <Popover content={(
                                                    <div className='popOverContent'>
                                                        {title}
                                                    </div>
                                                )}>
                                                    <div className='twoLine'>
                                                        {title}
                                                    </div>
                                                </Popover>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='sku'>
                                        {attr_name}
                                    </Col>
                                </Row>
                            </Col>

                            <Col className='sizeBox' style={{ width: 24 }}>
                                x{size}
                            </Col>
                        </Row>
                    )
                }
            },
            {
                title: '订单状态',
                dataIndex: 'status',
                render: (status) => (
                    <div style={{ color: statusMap[status].color, fontWeight: 'bold' }}>{statusMap[status].txt}</div>
                ),
                onCell: (data, index) => ({
                    rowSpan: data.rowSpan
                })
            },
            {
                title: '销售单价',
                dataIndex: 'price',
            }
        ];

        const {
            data = [],
            total = 0,
            current_page,
            currentOrderInfo = {},
            orderInfoModalVisible,
            currentOrderSkuList = [],
            originSearchParam
        } = this.state;
        return <>
            <Form initialValues={originSearchParam}
                ref={e => { this.editForm = e; }}
                layout="vertical"
                onFinish={this.search}
            >
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="订单状态" name="status">
                            <Select>
                                <Option value="-1">
                                    全部
                                </Option>
                                <Option value="1">
                                    待支付
                                </Option>
                                <Option value="2">
                                    待发货
                                </Option>
                                <Option value="3">
                                    已发货
                                </Option>
                                <Option value="4">
                                    换货申请中
                                </Option>
                                <Option value="5">
                                    换货配送
                                </Option>
                                <Option value="6">
                                    已完成
                                </Option>
                                <Option value="0">
                                    已关闭
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="订单号" name="order_num">
                            <Input placeholder='请输入' />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="商品spu名称" name="spu_name">
                            <Input placeholder='请输入' />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="收货人姓名" name="address_username">
                            <Input placeholder='请输入' />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="收货人手机号" name="address_phone">
                            <Input placeholder='请输入' />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="date" label="下单时间">
                            <RangePicker />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Row justify="start" style={{ height: '100%', paddingBottom: 24 }} align="bottom">
                            <Col>
                                <Button htmlType="reset" onClick={() => { this.setState({ searchParam: originSearchParam }) }}>
                                    重置
                                </Button>
                            </Col>
                            <Col>
                                <Button type="primary" htmlType="submit" style={{ marginLeft: 10 }}>
                                    查询
                                </Button>
                            </Col>
                        </Row>


                    </Col>
                </Row>
            </Form>

            <Table
                rowKey={row => row.id}
                columns={columns} dataSource={data} pagination={{
                    defaultCurrent: 1,
                    pageSize: originSearchParam.page_size,
                    current: current_page,
                    hideOnSinglePage: true,
                    total: total,
                    onChange: this.pageChange
                }} />

            <Modal
                title="订单详情"
                width={900}
                open={orderInfoModalVisible}
                destroyOnClose={true}
                onCancel={() => { this.setState({ orderInfoModalVisible: false }) }}
                onOk={() => { this.setState({ orderInfoModalVisible: false }) }}
            >
                <>

                    <Form layout="vertical" initialValues={{
                        ...currentOrderInfo
                    }}>
                        <Row>
                            <Col span={24} className='groupTitle'>基本信息</Col>
                            <Col span={24}>
                                <Row justify="space-between">
                                    <Col span={11}>
                                        <Item label="订单号" required name="order_num">
                                            <Input value="0101001" disabled />
                                        </Item>
                                    </Col>
                                    <Col span={11}>
                                        <Item label="下单时间" name="create_time">
                                            <Input value="" disabled />
                                        </Item>
                                    </Col>
                                </Row>

                                <Row justify="space-between">
                                    <Col span={11}>
                                        <Item label="下单人" name="user_id">
                                            <Input value="0101001" disabled />
                                        </Item>
                                    </Col>
                                    <Col span={11}>
                                        <Item label="收货人信息" name="address">
                                            <Input value="天津市武清区金科博翠湾" disabled />
                                        </Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} className='groupTitle'>订单明细</Col>
                            <Col span={24}>
                                <Row justify="space-between">
                                    <Col span={11}>
                                        <Item label="支付方式" required name="pay_type">
                                            <Input value="微信" disabled />
                                        </Item>
                                    </Col>
                                    <Col span={11}>
                                        <Item label="支付流水号" name="pay_id">
                                            <Input value="" disabled />
                                        </Item>
                                    </Col>
                                </Row>

                                <Row justify="space-between">
                                    <Col span={11}>
                                        <Item label="支付时间" name="pay_time">
                                            <Input value="" disabled />
                                        </Item>
                                    </Col>
                                    <Col span={11}>
                                        <Item label="订单总金额" name="price_sum">
                                            <Input value="" disabled />
                                        </Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </Form>
                    <Row>
                        <Table
                            style={{ width: '100%' }}
                            rowKey={row => row.id}
                            columns={orderInfoColumns} dataSource={currentOrderSkuList} pagination={{
                                defaultCurrent: 1,
                                pageSize: currentOrderSkuList.length,
                                hideOnSinglePage: true
                            }} />

                    </Row>
                </>
            </Modal>
        </>
    }
}

export default Category;