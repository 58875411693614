import React from 'react';
import { Layout, Button, theme, Row, Col, Form, Input, message} from 'antd';
import {
    UserOutlined,
    LockOutlined,
} from '@ant-design/icons';


import { API, request } from '../../utils';

const { login } = API;
const { Content } = Layout;

class Login extends React.Component {
    state = {

    }
    componentDidMount() {
        
    }

    formFinish(formData) {
        message.loading();

        // return
        request({
            url: login,
            data: {
                ...formData
            }
        }).then(res => {
            const data = res.data;
            console.log(data);
            if (data.status === 200) {
                const { localStorage } = window;
                localStorage.setItem('token', data.token);

                const { hash, origin, pathname } = window.location;

                let newPathArr = pathname.split('/');
                newPathArr.pop();
                const newPath = origin + newPathArr.join('/') + '/index.html';
                const newHash = hash ? hash : '';
                window.location.href = newPath + (newHash === '#/' ? '' : newHash);
                window.location.reload();
            } else {
                message.error('登录失败' + res.status);
            }

            message.destroy();
        }).catch(function (error) {
            message.error('登录失败');
        });
    }


    render() {
        return <Layout>
            <Content>
                <Row style={{ background: '#fff', height: '100vh' }} justify="center" align="middle">
                    <Col style={{ width: 360, marginTop: 0 }}>
                        <div style={{ fontSize: 24, fontWeight: 'bold', marginBottom: 14 }}>登录</div>
                        <Form onFinish={this.formFinish}>
                            <Form.Item
                                name="email"
                                rules={[{ required: true, message: '请输入登录账号' }]}
                            >
                                <Input size="large"
                                    style={{ borderRadius: 20 }}
                                    prefix={<UserOutlined className="site-form-item-icon" />}
                                    placeholder="登录账号" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: '请输入登录密码' }]}
                            >
                                <Input
                                    size="large"
                                    style={{ borderRadius: 20 }}
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder="登录密码"
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button size="large" htmlType="submit" type='primary' style={{ width: '100%', borderRadius: 20 }}>登录</Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Content>
        </Layout >
    }
}

export default Login;