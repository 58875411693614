import React from 'react';
import { Layout, Button, Select, Row, Col, Form, Input, message, DatePicker, Table } from 'antd';
import { request, API } from '../../utils';
const { getFeedback, updateOrderStatus } = API;
class Category extends React.Component {
    state = {
        pageSize: 10,
        total: 0,
        data: [],
        current_page: 1
    }

    componentDidMount() {
        this.getOpinionData();
    }

    getOpinionData = (params = {}) => {
        message.loading();
        const { page = 1 } = params
        const { pageSize = 10 } = this.state;
        request({
            url: getFeedback,
            params: {
                page,
                page_size: pageSize
            }
        }).then(res => {
            const data = res.data || {};
            if (data.code === 200) {
                const dataObj = data.data || {};
                const { total = 0, current_page = 1 } = dataObj;
                const list = dataObj.data || [];
                this.setState({
                    data: list,
                    total,
                    current_page
                });
            }
            message.destroy();

        }).catch(err => {
            message.error(err.message);
        })
    }

    pageChange(page) {
        this.getOpinionData({
            page
        });
    }

    render() {
        const {
            total,
            current_page,
            data
        } = this.state;
        const columns = [
            {
                title: '反馈人',
                dataIndex: 'user_id',
                key: 'user_id',
                width: 200
            },
            {
                title: '反馈时间',
                dataIndex: 'created_at',
                key: 'created_at',
                width: 200
            },
            {
                title: '反馈内容',
                dataIndex: 'content',
                key: 'content'
            }
        ];

        return <>
            <Table columns={columns} dataSource={data} pagination={{
                defaultCurrent: 1,
                pageSize: 10,
                current: current_page,
                hideOnSinglePage: true,
                total: total,
                onChange: this.pageChange
            }} rowKey={data => (data.id)} />
        </>
    }
}

export default Category;