import React from 'react';
import { Button, Row, Col, Modal, Form, Input, message, Popconfirm } from 'antd';
import { API, request } from '../../utils';

const { getCategories, addCategorie, updateCategorie, delCategorie } = API;

class Category extends React.Component {
    state = {
        editModalVisible: false,
        parentId: 0,
        currentItem: {},
        data: []
    }

    componentDidMount() {
        this.getCategories();
    }

    getCategories() {
        request({
            url: getCategories,
            params: {
                store_id: 1
            },
        }).then(res => {
            const data = res.data || {};
            if (data.code === 200) {
                this.setState({
                    data: data.data || []
                });
            }
        })
    }

    onFinish = (value) => {
        console.log(value);
    }

    editModalHandle(isEdit) {
        const { parentId, currentItem, data } = this.state;
        this.editForm.validateFields().then(values => {
            const { category } = values;
            if (isEdit) {
                const { currentParentId, currentItemId, name } = currentItem;
                // 未做修改 => 关闭弹窗
                if (category.trim() === name) {
                    this.setState({
                        editModalVisible: false,
                        currentItem: {},
                        isEdit: false
                    });
                } else {
                    message.loading();
                    request({
                        url: updateCategorie.url + '/' + currentItemId,
                        method: updateCategorie.method,
                        data: {
                            parent_id: currentParentId,
                            name: category || ''
                        },
                    }).then(res => {
                        const data = res.data || {};
                        if (data.code === 200) {
                            this.setState({
                                editModalVisible: false,
                                currentItem: {},
                                isEdit: false
                            });
                            this.getCategories();
                        }

                        message.destroy();
                    });
                }

            } else {
                message.loading();
                request({
                    url: addCategorie,
                    data: {
                        store_id: 1,
                        parent_id: parentId,
                        name: category
                    }
                }).then(res => {
                    const data = res.data || {};
                    if (data.code === 200) {
                        this.getCategories();
                        this.setState({
                            editModalVisible: false
                        });
                        message.destroy();
                    } else {
                        message.error(data.message);
                    }

                });
            }



        }).catch(err => { })
    }

    showAddModal(parentId) {
        this.setState({
            editModalVisible: true,
            parentId: parentId || 0,
            isEdit: false
        });
    }

    showEditModal(currentParentId, currentItemId, name) {
        this.setState({
            editModalVisible: true,
            isEdit: true,
            currentItem: {
                currentParentId,
                currentItemId,
                name
            }
        });
    }

    deleteHandle(parentId, itemId) {
        message.loading();
        request({
            url: delCategorie.url + '/' + itemId,
            method: delCategorie.method,
            data: {
                parent_id: parentId
            }
        }).then(res => {
            const data = res.data || {};
            if (data.code === 200) {
                message.success('删除成功');
            }
            this.getCategories();
        })
    }

    render() {

        const { editModalVisible, isEdit, data,
            currentItem = {}
        } = this.state;

        return <div>
            <Button type="primary" onClick={() => { this.showAddModal() }}>添加类目</Button>
            <Row align="middle" style={{ backgroundColor: '#FAFAFA', height: 42, borderRadius: 5, padding: 10, marginTop: 20 }}>
                <Col span={5}>操作</Col>
                <Col span={15}>类目名称</Col>
            </Row>
            {
                data.map(parent => (
                    <Row align="middle" key={parent.id} style={{ minHeight: 42, boxSizing: 'border-box', borderBottom: '1px solid #f4f4f4' }}>
                        <Col span={5}>
                            <Row>
                                <Col>
                                    <Button type="link" onClick={() => { this.showAddModal(parent.id); }}>添加下级</Button>
                                </Col>
                                <Col>
                                    <Button type="link" onClick={() => { this.showEditModal(0, parent.id, parent.name); }}>编辑</Button>
                                </Col>
                                <Col>
                                    <Popconfirm
                                        title="删除类目"
                                        description="确认删除这个类目项吗?"
                                        onConfirm={() => { this.deleteHandle(0, parent.id); }}
                                        okText="确定"
                                        cancelText="取消"
                                    >
                                        <Button type="link">删除</Button>
                                    </Popconfirm>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={19}>
                            <Row style={{ padding: 10 }}>
                                <Col>
                                    {parent.name}
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            {parent.son_categories && parent.son_categories.map(item => (
                                <Row align="middle" style={{ height: 42, boxSizing: 'border-box', borderTop: '1px solid #f4f4f4' }} key={parent.id + '' + item.id}>
                                    <Col span={5}>
                                        <Row>
                                            <Col>
                                                <Button type="link" onClick={() => { this.showEditModal(item.parent_id, item.id, item.name) }}>编辑</Button>
                                            </Col>
                                            <Col>
                                                <Popconfirm
                                                    title="删除类目"
                                                    description="确认删除这个类目项吗?"
                                                    onConfirm={() => { this.deleteHandle(item.parent_id, item.id); }}
                                                    okText="确定"
                                                    cancelText="取消"
                                                >
                                                    <Button type="link">删除</Button>
                                                </Popconfirm>

                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={15}>
                                        <Row style={{ padding: 10 }}>
                                            <Col>
                                                {item.name}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            ))}
                        </Col>
                    </Row>
                ))
            }

            <Modal
                title={isEdit ? "修改类目" : "添加类目"}
                width={500}
                open={editModalVisible}
                onOk={() => { this.editModalHandle(isEdit) }}
                onCancel={() => { this.setState({ editModalVisible: false }) }}
                destroyOnClose={true}
            >
                <Form
                    initialValues={{
                        store: '天津市武清区金科博翠湾',
                        category: currentItem.name || ''
                    }}
                    ref={e => { this.editForm = e; }}
                >
                    <Form.Item label="类目名称"
                        name="category"
                        rules={[
                            {
                                required: true,
                                message: '请输入类目名称',
                            }
                        ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="归属仓库"
                        name="store"
                        rules={[
                            {
                                required: true,
                                message: '请输入仓库名称',
                            }
                        ]}>
                        <Input disabled />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    }
}

export default Category;