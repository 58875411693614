import React from 'react';
import {
    Upload, Button, Select, Row,
    Steps,
    Image,
    Col, Form, Flex, Input, message, DatePicker, Table, Modal,
} from 'antd';
import dayjs from 'dayjs';
import { LoadingOutlined, PlusOutlined, DeleteOutlined, EyeOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { request, API, deleteFromList, updateFromList } from '../../utils';
import './index.css';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { getGoodses, addGood, updateGood,
    updateGoodAttr,
    updateSku,
    updateGoodStatus, uploadPic, getCategories } = API;


const proFormRef = React.createRef();
const skuFormRef = React.createRef();
const skuStockFormRef = React.createRef();

const { Item } = Form;
const { confirm } = Modal;

const store_id = 1;

class Product extends React.Component {
    state = {
        searchParam: {
            page: 1,
            page_size: 10,
            status: '0',
            category_id: '0',
            spu_num: '',
            sku_num: '',
            title: '',
        },
        current_page: 1,
        data: [],
        total: 0,

        // 弹窗
        proModalVisible: false,
        currentIndex: 0,
        // 商品类目
        categories: [], //类目
        store_id: store_id,
        // 商品信息字段
        spuTopImgList: [], // 头图(视频/图片)
        spuParamsArr: [], // spu参数
        spuDetailImgs: [], // 商品详情图
        skuList: [], // sku列表
        proPosterImg: '', // 封面图
        currentGoodInfo: {
            id: '',
            title: '',
            show: '',
            category_id: '',
            status: 1,
            store_id: store_id,
            order: 1
        },

        // 用来对比是否有更新
        originSkuDataString: '',
        originGoodDataString: '',
        originAttrDataString: '',

        skuAttrArr: [{
            attr_name: '颜色',
            attr_value: [
                {
                    attr_val: '黑色',
                    attr_img: ''
                },
                {
                    attr_val: '白色',
                    attr_img: ''
                },
                {
                    attr_val: '蓝色',
                    attr_img: ''
                },
            ]
        }, {
            attr_name: '尺寸',
            attr_value: [
                {
                    attr_val: '大',
                },
                {
                    attr_val: '中',
                },
                {
                    attr_val: '小',
                },
            ]
        }], // sku属性集合

        skuModalVisible: false,
    }

    componentDidMount() {
        this.getProductData();
        this.token = localStorage.getItem('token');
        this.getCategories();

    }

    getCategories(store_id = 1) {
        request({
            url: getCategories,
            params: {
                store_id
            },
        }).then(res => {
            const data = res.data || {};
            if (data.code === 200) {
                const list = data.data || [];
                let categories = [];
                list.forEach((item => {
                    const sonList = item.son_categories || [];
                    sonList.forEach(son => {
                        categories.push({
                            name: `${item.name}/${son.name}`,
                            id: son.id
                        })
                    })

                }))
                this.setState({
                    categories: categories
                });
            }
        })
    }

    getProductData = (params = {}) => {
        message.loading();
        const { searchParam } = this.state;
        const mergeParams = {
            ...searchParam,
            ...params
        }
        this.setState({
            searchParam: {
                ...mergeParams
            }
        })
        request({
            url: getGoodses,
            params: mergeParams
        }).then(res => {
            const data = res.data || {};
            if (data.code === 200) {
                const dataObj = data.data || {};
                const { total = 0, current_page = 1 } = dataObj;
                const list = dataObj.data || [];
                this.setState({
                    data: list,
                    total,
                    current_page
                });
            }
            message.destroy();

        }).catch(err => {
            message.error(err.message);
        })
    }

    pageChange = (page) => {
        this.getProductData({
            page
        });
    }

    search = (params) => {
        this.getProductData({
            ...params
        })
    }

    pushGoodsModalHandle() {
        this.setState({
            currentIndex: 0,
            proModalVisible: true,
            isEdit: true
        });
        this.initDefaultGoodHandle();
    }

    closeGoodModalHandle = () => {
        this.setState({
            proModalVisible: false
        });
    }

    initDefaultGoodHandle() {
        this.setState({
            currentGoodInfo: {
                id: '',
                title: '',
                show: '',
                category_id: ''
            },
            proPosterImg: '',
            spuTopImgList: [],
            spuParamsArr: [],
            spuDetailImgs: [],
            skuAttrArr: [],
            skuList: [],
            currentIndex: 0
        })
    }

    // getBase64 = (img, callback) => {
    //     const reader = new FileReader();
    //     reader.addEventListener('load', () => callback(reader.result));
    //     reader.readAsDataURL(img);
    // };
    beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        // const isLt2M = file.size / 1024 / 1024 < 2;
        // if (!isLt2M) {
        //     message.error('Image must smaller than 2MB!');
        // }
        return isJpgOrPng;
    };

    handleImgChange = (info, key) => {
        if (info.file.status === 'uploading') {
            message.loading({
                content: '上传中'
            });
            return;
        }
        if (info.file.status === 'done') {
            const { data } = info.file.response || {};
            const { url } = data;
            message.destroy();

            this.setState({
                [key]: url
            });
        }
    };

    handleImgListChange = (info, key) => {
        if (info.file.status === 'uploading') {
            message.loading({
                content: '上传中'
            });
            return;
        }
        if (info.file.status === 'done') {
            const { data } = info.file.response || {};
            const { url } = data;
            message.destroy();

            const list = this.state[key] || [];
            this.setState({
                [key]: [...list, url]
            });
        }
    };

    prevPageHandle = () => {
        const {
            currentIndex
        } = this.state;
        this.setState({
            currentIndex: currentIndex - 1
        });
    }

    nextPageHandle = () => {
        const {
            currentIndex,
            isEdit
        } = this.state;

        if (!isEdit) {
            this.setState({
                currentIndex: currentIndex === 2 ? 0 : currentIndex + 1,
                proModalVisible: currentIndex !== 2
            });
        } else {
            const cb = () => {
                this.setState({
                    currentIndex: currentIndex === 2 ? 0 : currentIndex + 1,
                    proModalVisible: currentIndex !== 2
                });
                this.getProductData();
            };
            if (currentIndex === 0) {
                this.updateGoodHandle(cb);
            } else if (currentIndex === 1) {
                this.updateGoodAttrHandle(cb);
            } else {
                this.updateGoodSkuHandle(cb);
            }
        }
    }

    toggleGoodStatus(data = {}, status) {
        const { title, id } = data;
        confirm({
            title: `确定要将商品${status === 1 ? '上架' : '下架'}？`,
            content: <>
                {title + '将会' + (status === 1 ? '上架' : '下架')}
            </>,
            onOk: () => {
                message.loading();

                request({
                    url: updateGoodStatus,
                    urlParamStr: id,
                    data: {
                        status: status
                    }
                }).then(res => {
                    const { code, data } = res.data || {};
                    if (code === 200) {
                        this.getProductData();
                    }
                    message.destroy();
                })
            }
        })
    }

    // 提交商品修改/创建信息
    updateGoodHandle(cb) {
        const form = proFormRef.current;
        form.validateFields().then(fileds => {
            const { category_id, title, show = '' } = fileds || {};
            const {
                proPosterImg,
                spuTopImgList,
                spuParamsArr,
                spuDetailImgs,
                currentGoodInfo
            } = this.state;

            if (category_id && title && proPosterImg && spuTopImgList.length !== 0 && spuDetailImgs.length !== 0) {
                if (spuParamsArr.length !== 0) {
                    const emptyParam = spuParamsArr.find(item => !item.key || !item.val);
                    if (emptyParam) {
                        message.error('spu商品详情图中：动态参数不可为空，如不需要请删除');
                        return;
                    }
                }

                const {
                    originGoodDataString,
                    proPosterImg,
                    spuTopImgList,
                    spuDetailImgs
                } = this.state;

                const {
                    id,
                    status,
                    order,
                } = currentGoodInfo || {};

                const paramsJSONString = JSON.stringify({
                    id,
                    title,
                    show,
                    category_id,
                    status,
                    order,
                    store_id: currentGoodInfo.store_id || store_id,
                    proPosterImg,
                    spuTopImgList,
                    spuParamsArr,
                    spuDetailImgs
                });

                // 避免重复提交，判断是否修改
                if (paramsJSONString === originGoodDataString) {
                    cb();
                    return;
                }

                request({
                    url: id ? updateGood : addGood,
                    urlParamStr: id,
                    data: {
                        thumb: proPosterImg,
                        category_id,
                        title,
                        banner: spuTopImgList,
                        params: spuParamsArr,
                        show,
                        store_id: currentGoodInfo.store_id || store_id,
                        order: order || 1,
                        status: status || 2,
                        imgs: spuDetailImgs,
                    }
                }).then(res => {
                    const { code, data } = res.data || {};
                    if (code === 200) {

                        const { id } = data || {};
                        this.setState({
                            currentGoodInfo: {
                                id,
                                ...currentGoodInfo
                            },

                            originGoodDataString: paramsJSONString
                        });
                        cb();
                    }
                })
            } else {
                message.error('请填写必填项');
                return;
            }
        })
    }

    editGoodHandle(data, isEdit) {
        const {
            title, params, banner, category_id, id, imgs,
            show,
            thumb,
            status,
            order,
            skus = []
        } = data;

        const originGoodInfo = {
            id,
            title,
            show,
            category_id,
            status,
            order,
            store_id: store_id,
        };

        const originGoodData = {
            ...originGoodInfo,
            proPosterImg: thumb,
            spuTopImgList: banner,
            spuParamsArr: params,
            spuDetailImgs: imgs
        };

        const sku_attrs = data.attrs || []; // 防止接口返回null
        const originSkuAttr = sku_attrs.length === 0 ? [{}] : sku_attrs; //规格最少有一个


        this.setState({
            isEdit,
            currentGoodInfo: {
                ...originGoodInfo
            },
            originGoodDataString: JSON.stringify(originGoodData),
            spuDetailImgs: imgs,
            spuParamsArr: params,
            spuTopImgList: banner,
            proPosterImg: thumb,
            skuAttrArr: originSkuAttr,
            skuList: skus || [],
            originSkuDataString: JSON.stringify(skus),
            originAttrDataString: JSON.stringify(originSkuAttr),
            proModalVisible: true,
            currentIndex: 0
        })
    }

    // 更新规格
    updateGoodAttrHandle(cb) {
        const { skuAttrArr, originAttrDataString } = this.state;
        let hasEmpty = false;
        for (let i = 0; i < skuAttrArr.length; i++) {
            const skuGroup = skuAttrArr[i];
            const { attr_name, attr_value } = skuGroup;
            const skuArr = attr_value || [];
            if (!attr_name) {
                hasEmpty = true;
                message.error('请填入已添加的sku属性名');
                return
            } else if (skuArr.length === 0) {
                hasEmpty = true;
                message.error('请设置的sku属性值');
                return
            } else {
                for (let index = 0; index < skuArr.length; index++) {
                    const sku = skuArr[index];
                    const { attr_val, attr_img } = sku;
                    if (i === 0) {
                        if (!attr_img) {
                            hasEmpty = true;
                            message.error('请补充完整主sku图片');
                            return
                        }
                    } else {
                        // 接口要求：必须穿attr_img字段
                        skuArr[index].attr_img = '';
                    }
                    if (!attr_val) {
                        hasEmpty = true;
                        message.error('请填入已添加的sku属性值信息');
                        return;
                    }
                }
            }
        }

        if (originAttrDataString === JSON.stringify(skuAttrArr)) {
            cb && cb();
            return
        };

        if (!hasEmpty) {
            const { currentGoodInfo } = this.state;
            request({
                url: updateGoodAttr,
                urlParamStr: currentGoodInfo.id,
                data: [
                    ...skuAttrArr
                ]
            }).then(res => {
                const data = res.data || {};
                if (data.code === 200) {
                    const attrList = data.data || [];
                    const newSkuList = this.initSkuHandle(attrList);
                    this.setState({
                        skuList: [
                            ...newSkuList
                        ],
                        originAttrDataString: JSON.stringify(attrList),
                        skuAttrArr: attrList,
                    });

                    cb && cb();
                }
            })
        }
    }

    initSkuHandle(attrList) {
        const { skuList } = this.state;
        // 处理数据
        let processedData = [];
        attrList.forEach((obj, index) => {
            if (index === 0) {
                processedData = obj.attr_value.map(attr => {
                    return {
                        attrs: attr.id + '',
                        attr_img: attr.attr_img, // 第一个属性规格只有且必须带图
                        attr_name: attr.attr_val,
                    }
                });
            } else {
                let tempArray = [];
                obj.attr_value.forEach(attr => {
                    processedData.forEach(sku => {
                        tempArray.push({
                            attrs: sku.attrs + '_' + attr.id,
                            attr_img: sku.attr_img,
                            attr_name: sku.attr_name + '/' + attr.attr_val
                        });
                    });
                });
                processedData = tempArray;
            }
        });

        console.log('==processedData==', processedData);

        processedData = processedData.map(sku => {
            const originSku = skuList.find(item => item.attrs === sku.attrs);
            return {
                size: 0,
                price: 0,
                cost_price: 0,
                ...originSku,
                ...sku,
            }
        })

        return processedData;
    }

    updateGoodSkuHandle(cb) {
        const { skuList, currentGoodInfo, originSkuDataString } = this.state;

        let hasEmpty = false;
        for (let i = 0; i < skuList.length; i++) {
            const skuData = skuList[i];
            const { price } = skuData;
            if (price === '' || price === 0) {
                hasEmpty = true;
                message.error('请填入sku的销售价格');
                return
            }
        }

        for (let index = 0; index < skuList.length; index++) {
            const sku = skuList[index];
            delete sku.attr_val;
        }

        if (JSON.stringify(skuList) === originSkuDataString) {
            cb();
            return;
        }

        if (!hasEmpty) {
            request({
                url: updateSku,
                urlParamStr: currentGoodInfo.id,
                data: skuList
            }).then(res => {
                const data = res.data || {};
                if (data.code === 200) {
                    cb();
                    this.getProductData();
                }
            })
        }
    }

    previewImgHandle(img) {
        this.setState({
            previewImgVisitble: true,
            previewImg: img
        });
    }

    deleteImgFromList(key, index) {
        const list = this.state[key] || [];
        this.setState({
            [key]: deleteFromList(list, index)
        });
    }

    addSpuParamsArrHandle = () => {
        const { spuParamsArr } = this.state;
        this.setState({
            spuParamsArr: [...spuParamsArr, {}]
        });
    }

    deleteSpuParamsArrHandle(i) {
        let spuParamsArr = this.state.spuParamsArr || [];
        this.setState({
            spuParamsArr: deleteFromList(spuParamsArr, i)
        });
    }

    paramsInputHandle(v, i, type) {
        let list = this.state.spuParamsArr || [];
        list[i][type] = v;
        this.setState({
            spuParamsArr: list
        })
    }

    skuAttrInputHandle(val, firstIndex, secondIndex) {
        let skuAttrArr = this.state.skuAttrArr || [];
        if (secondIndex !== undefined) {
            skuAttrArr[firstIndex]['attr_value'][secondIndex]['attr_val'] = val;
        } else {
            skuAttrArr[firstIndex]['attr_name'] = val;
        }
        this.setState({
            skuAttrArr
        });
    }

    handleSkuImgListChange(info, i, j) {
        if (info.file.status === 'uploading') {
            message.loading({
                content: '上传中'
            });
            return;
        }
        if (info.file.status === 'done') {
            const { data } = info.file.response || {};
            const { url } = data;
            message.destroy();

            const list = this.state.skuAttrArr || [];
            list[i]['attr_value'][j].attr_img = url;

            this.setState({
                skuAttrArr: [...list]
            });
        }
    }

    deleteSkuAttrHandle(groupIndex, skuIndex, type) {
        const { skuAttrArr } = this.state;
        if (type === 'img') {
            skuAttrArr[groupIndex]['attr_value'][skuIndex].attr_img = '';
        } else {
            skuAttrArr[groupIndex]['attr_value'].splice(skuIndex, 1);
        }
        this.setState({
            skuAttrArr
        })
    }

    deleteSkuGroupHandle(groupIndex) {
        const { skuAttrArr } = this.state;
        if (skuAttrArr.length === 1) {
            message.warning('需要保留至少一项规格');
            return;
        }
        this.setState({
            skuAttrArr: deleteFromList(skuAttrArr, groupIndex)
        })
    }

    addSkuGroupHandle = () => {
        const { skuAttrArr } = this.state;
        this.setState({
            skuAttrArr: [
                ...skuAttrArr,
                {
                    attr_name: '',
                    attr_value: []
                }
            ]
        })
    }

    addAttrHandle = (groupIndex) => {
        const { skuAttrArr } = this.state;
        let attrValue = skuAttrArr[groupIndex]['attr_value'] || [];
        attrValue.push({});
        skuAttrArr[groupIndex]['attr_value'] = attrValue;
        this.setState({
            skuAttrArr
        })
    }

    showSkuInfoModal(data) {
        const skus = data.skus || [];
        this.setState({
            currentGoodInfo: {
                ...data
            },
            skuViewData: skus,
            skuModalVisible: true
        });

    }

    skuChangeHandle(val, index, key) {
        let { skuList } = this.state;
        skuList[index][key] = val;
        this.setState({
            skuList
        });
    }

    render() {
        const columns = [
            {
                title: '操作',
                dataIndex: 'status',
                width: 220,
                render: (status, data) => (
                    <>
                        <Button type='link' onClick={() => this.toggleGoodStatus(data, status === 1 ? 2 : 1)}>{status === 1 ? '下架' : '上架'}</Button>
                        <Button type='link' onClick={() => {
                            this.editGoodHandle(data, true);
                        }}>编辑</Button>
                        <Button type='link' onClick={() => {
                            this.editGoodHandle(data, false);
                        }}>查看</Button>
                    </>
                )
            },
            {
                title: '商品状态',
                dataIndex: 'status',
                width: 100,
                render: status => (
                    <>
                        <span className={status === 1 ? 'online' : 'offline'}></span>
                        {status === 1 ? '上架中' : '已下架'}

                    </>
                )
            },
            {
                title: '商品图片',
                dataIndex: 'thumb',
                render: (img) => (
                    <Image src={img} width={44} />
                )
            },
            {
                title: '商品spu名称',
                dataIndex: 'title',
                width: 203,
                render: title => <div className='twoLine'>{title}</div>
            },
            {
                title: '商品类目',
                dataIndex: 'category',
                render: category => {
                    const { name, parent_category } = category;
                    return (
                        <div>
                            {parent_category ? `${parent_category.name}/` : ''}{name}
                        </div>
                    )
                }
            },
            {
                title: 'sku信息',
                dataIndex: 'id',
                render: (_, data) => (
                    <Button type='link' onClick={() => { this.showSkuInfoModal(data); }}>查看</Button>
                )
            },
            {
                title: 'spu编码',
                dataIndex: 'spu_num',
            },
            {
                title: '创建时间',
                dataIndex: 'created_at',
            }
        ];

        const skuColumns = [
            {
                title: 'sku名称',
                dataIndex: 'attr_name',
                width: 100,
                render: attr_name => <div className='twoLine'>{attr_name}</div>
            },
            {
                title: '库存',
                dataIndex: 'size',
            },
            {
                title: '售价',
                dataIndex: 'price'
            },
            {
                title: '成本价',
                dataIndex: 'cost_price'
            },
        ];

        const {
            data = [],
            total = 0,
            current_page,
            proModalVisible,
            loading,
            spuParamsArr = [],
            spuTopImgList = [],
            currentIndex = 0,
            categories = [],
            proPosterImg = '',
            previewImgVisitble,
            previewImg,
            spuDetailImgs = [],
            skuAttrArr = [],
            skuList = [],
            currentGoodInfo,
            searchParam,
            isEdit,
            skuModalVisible,
            skuViewData = []
        } = this.state;

        return <>
            <Form initialValues={{
                ...searchParam
            }}
                ref={e => { this.editForm = e; }}
                layout="vertical"
                onFinish={this.search}
            >
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="商品状态" name="status">
                            <Select>
                                <Option value="0">
                                    全部
                                </Option>
                                <Option value="1">
                                    上架
                                </Option>
                                <Option value="2">
                                    下架
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label="商品类目" name="category_id">
                            <Select>
                                <Option value="0">
                                    全部
                                </Option>
                                {
                                    categories.map(category => (
                                        <Option key={category.id} value={category.id}>{category.name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="商品spu编码" name="spu_num">
                            <Input placeholder='请输入' />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="商品spu名称" name="title">
                            <Input placeholder='请输入' />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="商品sku编码" name="sku_num">
                            <Input placeholder='请输入' />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Row justify="start" style={{ height: '100%', paddingBottom: 24 }} align="bottom">
                            <Col>
                                <Button htmlType="reset">
                                    重置
                                </Button>
                            </Col>
                            <Col>
                                <Button type="primary" htmlType="submit" style={{ marginLeft: 10 }}>
                                    查询
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>

            <Row>
                <Col>
                    <Button type="primary" style={{ marginBottom: 10 }} onClick={() => { this.pushGoodsModalHandle() }}>
                        发布商品
                    </Button>
                </Col>
            </Row>

            <Table columns={columns} dataSource={data} pagination={{
                defaultCurrent: 1,
                current: current_page,
                pageSize: searchParam.page_size || 10,
                hideOnSinglePage: true,
                total: total,
                onChange: this.pageChange
            }} rowKey={item => item.id} />

            <Modal
                title="发布商品"
                width={1440}
                style={{ minWidth: 1000 }}
                open={proModalVisible}
                destroyOnClose={true}
                onCancel={this.closeGoodModalHandle}
                footer={() => (
                    <Row justify="end">
                        <Col style={{ marginRight: 10 }}>
                            <Button onClick={this.closeGoodModalHandle}>取消</Button>
                        </Col>
                        {
                            currentIndex > 0 && (
                                <Col style={{ marginRight: 10 }}>
                                    <Button onClick={this.prevPageHandle}>上一步</Button>
                                </Col>
                            )
                        }
                        <Col>
                            <Button type={isEdit ? 'primary' : 'default'} onClick={this.nextPageHandle}>{currentIndex === 2 ? (isEdit ? '提交' : '确定') : '下一步'}</Button>
                        </Col>
                    </Row>
                )}
            >
                <>
                    <Steps
                        style={{ margin: '20px 0' }}
                        current={currentIndex}
                        items={[
                            {
                                title: 'spu基本信息'
                            },
                            {
                                title: 'sku属性'
                            },
                            {
                                title: 'sku基本信息'
                            }
                        ]}
                    />

                    {/* 第一页 */}
                    <div style={{ display: currentIndex === 0 ? 'block' : 'none' }}>
                        <Form layout="vertical" ref={proFormRef} initialValues={{
                            ...currentGoodInfo
                        }}>
                            <Row>
                                <Col span={24} className='groupTitle'>基本信息</Col>
                                <Col span={24}>
                                    <Row justify="space-between">
                                        <Col span={11}>
                                            <Item label="商品类目" required name="category_id">
                                                <Select value={currentGoodInfo.category_id} disabled={!isEdit}>
                                                    {
                                                        categories.map(category => (
                                                            <Option key={category.id} value={category.id}>{category.name}</Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Item>
                                        </Col>
                                        <Col span={11}>
                                            <Item label="spu邮编">
                                                <Input value="0101001" disabled />
                                            </Item>
                                        </Col>
                                    </Row>

                                    <Row justify="space-between">
                                        <Col span={11}>
                                            <Item label="商品名称" required name="title">
                                                <Input placeholder='请输入' value={currentGoodInfo.title} disabled={!isEdit} />
                                            </Item>
                                        </Col>
                                        <Col span={11}>
                                            <Item label="归属仓库">
                                                <Input value="天津市武清区金科博翠湾" disabled />
                                            </Item>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className='groupTitle'>spu图片</Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Item label="封面图" required name="thumb">
                                                <Row>
                                                    <Col span={4}>
                                                        {
                                                            proPosterImg ?
                                                                <div className='imgBox'>
                                                                    <div className='imgBoxBtns'>
                                                                        <EyeOutlined className='pointer' onClick={() => {
                                                                            this.previewImgHandle(proPosterImg)
                                                                        }} />
                                                                        {
                                                                            isEdit && (
                                                                                <DeleteOutlined className='pointer' onClick={() => {
                                                                                    this.setState({
                                                                                        proPosterImg: ''
                                                                                    })
                                                                                }} />
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div className='imgItem' style={{ backgroundImage: `url(${proPosterImg})` }} ></div>
                                                                </div>
                                                                : (
                                                                    <Row>
                                                                        {
                                                                            isEdit && (
                                                                                <Upload
                                                                                    headers={{
                                                                                        Authorization: this.token
                                                                                    }}
                                                                                    name="pic"
                                                                                    listType="picture-card"
                                                                                    className="avatar-uploader"
                                                                                    showUploadList={false}
                                                                                    action={uploadPic.url}
                                                                                    onChange={(info) => {
                                                                                        this.handleImgChange(info, 'proPosterImg')
                                                                                    }}
                                                                                >
                                                                                    <div>
                                                                                        {loading ? <LoadingOutlined /> : <PlusOutlined />}
                                                                                        <div
                                                                                            style={{
                                                                                                marginTop: 8,
                                                                                            }}
                                                                                        >
                                                                                            上传
                                                                                        </div>
                                                                                    </div>
                                                                                </Upload>
                                                                            )
                                                                        }
                                                                    </Row>
                                                                )
                                                        }
                                                    </Col>
                                                    <Col>
                                                        <Row>
                                                            用于商品列表、购物车、订单页展示；图片大小不超过3M，建议尺寸为344x344px；仅支持 jpeg、 png、 bmp 、gif 格式；
                                                        </Row>
                                                    </Col>
                                                </Row>

                                            </Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Item label="头图(视频/图片)" required name="banner">
                                                <Row>
                                                    {
                                                        spuTopImgList.map((img, index) => (
                                                            <Col key={index} span={3} style={{ marginBottom: 6 }}>
                                                                <div className='imgBox'>
                                                                    <div className='imgBoxBtns'>
                                                                        <EyeOutlined className='pointer' onClick={() => {
                                                                            this.previewImgHandle(img);
                                                                        }} />
                                                                        {
                                                                            isEdit && (
                                                                                <DeleteOutlined className='pointer' onClick={() => {
                                                                                    this.deleteImgFromList('spuTopImgList', index);
                                                                                }} />
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div className='imgItem' style={{ backgroundImage: `url(${img})` }} ></div>
                                                                </div>
                                                            </Col>
                                                        ))
                                                    }
                                                    <Col>
                                                        {
                                                            isEdit && (
                                                                <Upload
                                                                    headers={{
                                                                        Authorization: this.token
                                                                    }}
                                                                    name="pic"
                                                                    listType="picture-card"
                                                                    className="avatar-uploader"
                                                                    showUploadList={false}
                                                                    action={uploadPic.url}
                                                                    // beforeUpload={this.beforeUpload}
                                                                    onChange={(info) => {
                                                                        this.handleImgListChange(info, 'spuTopImgList');
                                                                    }}
                                                                >
                                                                    <div>
                                                                        {loading ? <LoadingOutlined /> : <PlusOutlined />}
                                                                        <div
                                                                            style={{
                                                                                marginTop: 8,
                                                                            }}
                                                                        >
                                                                            上传
                                                                        </div>
                                                                    </div>
                                                                </Upload>
                                                            )
                                                        }
                                                    </Col>
                                                    <Col span={24}>
                                                        <Row>
                                                            用于商品详情图头部滚动展示；最多10张，限制300MB以内；图片大小不超过3M，建议尺寸为750x750px；仅支持 jpeg、 png、 bmp 、gif 格式；视频大小不超过300M，仅支持.mp4、.m4v格式；
                                                        </Row>
                                                    </Col>
                                                </Row>

                                            </Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className='groupTitle'>spu商品详情图</Col>
                                <Col span={24}>
                                    {
                                        spuParamsArr.map((param, i) => (
                                            <Row key={i} align='middle'>
                                                <Col span={10}>
                                                    <Item label="参数名称" required>
                                                        <Input placeholder='请输入' disabled={!isEdit} onChange={(e) => {
                                                            this.paramsInputHandle(e.target.value, i, 'key')
                                                        }} value={param.key} />
                                                    </Item>
                                                </Col>
                                                <Col span={10} style={{ marginLeft: 10 }}>
                                                    <Item label="参数值" required>
                                                        <Input placeholder='请输入' disabled={!isEdit} onChange={(e) => {
                                                            this.paramsInputHandle(e.target.value, i, 'val')
                                                        }} value={param.val} />
                                                    </Item>
                                                </Col>

                                                {
                                                    isEdit && (
                                                        <Col span={1} justify="center" align='middle'>
                                                            <DeleteOutlined style={{ cursor: 'pointer', fontSize: 20, color: '#91969C', marginTop: 10 }} onClick={() => { this.deleteSpuParamsArrHandle(i); }} />
                                                        </Col>
                                                    )
                                                }

                                            </Row>
                                        ))
                                    }

                                    {
                                        isEdit && (
                                            <Row >
                                                <Col span={10}>
                                                    <Button className='addParam' type='dashed' onClick={this.addSpuParamsArrHandle}>添加参数</Button>
                                                </Col>
                                            </Row>
                                        )
                                    }

                                </Col>

                                <Col span={24} style={{ marginTop: 20 }}>
                                    <Row>
                                        <Col span={20}>
                                            <Item label="商品说明（位于商品详情页底部说明）" name="show">
                                                <Input.TextArea disabled={!isEdit} value="1.内含弹射结构，儿童需在成年人监护下使用，请勿设计人和动物。2.请注意避免儿童吞咽子弹。" />
                                            </Item>
                                            <Flex justify="end" style={{ marginTop: -20 }}>
                                                0/200
                                            </Flex>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col span={24}>
                                    <Item label="商品详情图" required name="imgs">
                                        <Row>
                                            {
                                                spuDetailImgs.map((img, index) => (
                                                    <Col key={index} span={3} style={{ marginBottom: 6 }}>
                                                        <div className='imgBox'>
                                                            <div className='imgBoxBtns'>
                                                                <EyeOutlined className='pointer' onClick={() => {
                                                                    this.previewImgHandle(img);
                                                                }} />
                                                                {
                                                                    isEdit && (
                                                                        <DeleteOutlined className='pointer' onClick={() => {
                                                                            this.deleteImgFromList('spuDetailImgs', index);
                                                                        }} />
                                                                    )
                                                                }
                                                            </div>
                                                            <div className='imgItem' style={{ backgroundImage: `url(${img})` }} ></div>
                                                        </div>
                                                    </Col>
                                                ))
                                            }
                                            <Col>
                                                <Row>
                                                    {
                                                        isEdit && (
                                                            <Upload
                                                                headers={{
                                                                    Authorization: this.token
                                                                }}
                                                                name="pic"
                                                                listType="picture-card"
                                                                className="avatar-uploader"
                                                                showUploadList={false}
                                                                action={uploadPic.url}
                                                                // beforeUpload={this.beforeUpload}
                                                                onChange={(info) => {
                                                                    this.handleImgListChange(info, 'spuDetailImgs');
                                                                }}
                                                            >
                                                                <div>
                                                                    {loading ? <LoadingOutlined /> : <PlusOutlined />}
                                                                    <div
                                                                        style={{
                                                                            marginTop: 8,
                                                                        }}
                                                                    >
                                                                        上传
                                                                    </div>
                                                                </div>
                                                            </Upload>
                                                        )
                                                    }
                                                </Row>

                                                <Row>
                                                    最多10张，限制300MB以内；图片大小不超过3M；仅支持 jpeg、 png、 bmp 、gif 格式；
                                                </Row>
                                            </Col>
                                        </Row>

                                    </Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>

                    {/* 第二页 */}
                    {
                        currentIndex === 1 && (
                            <Form layout="vertical" ref={skuFormRef}>
                                <Row>
                                    <Col span={24} className='groupTitle'>
                                        sku属性（规格）
                                        {
                                            isEdit && (
                                                <Button type='primary' onClick={this.addSkuGroupHandle}>添加属性</Button>
                                            )
                                        }
                                    </Col>
                                    <Col span={24}>
                                        {
                                            skuAttrArr.map((item, i) => (
                                                <React.Fragment key={i}>
                                                    <Row align='middle'>
                                                        <Col span={10}>
                                                            <Item label={`销售属性名（属性${i + 1}）`} required>
                                                                <Input placeholder='请输入' disabled={!isEdit} showCount maxLength={100} onChange={(e) => {
                                                                    this.skuAttrInputHandle(e.target.value, i)
                                                                }} value={item.attr_name} />
                                                            </Item>
                                                        </Col>

                                                        {
                                                            isEdit && (
                                                                <Col span={1} justify="center" align='middle'>
                                                                    <DeleteOutlined style={{ cursor: 'pointer', fontSize: 20, color: '#91969C', marginTop: 10 }} onClick={() => { this.deleteSkuGroupHandle(i); }} />
                                                                </Col>
                                                            )
                                                        }
                                                    </Row>

                                                    <Row align='middle' justify='start' style={{ marginBottom: i === 0 ? 40 : 10 }}>
                                                        {
                                                            item.attr_value && item.attr_value.map((sku, j) => (
                                                                <Col key={j} span={4} style={{ marginRight: 20, height: i === 0 ? 214 : 'auto' }}>
                                                                    <Item label={
                                                                        <>
                                                                            属性值信息
                                                                            {
                                                                                isEdit && (
                                                                                    <DeleteOutlined className='skuDelIcon' onClick={() => { this.deleteSkuAttrHandle(i, j); }} />
                                                                                )
                                                                            }
                                                                        </>
                                                                    } required style={{ marginBottom: 0 }}>
                                                                        <Input placeholder='请输入' disabled={!isEdit} style={{ marginBottom: 10 }} onChange={(e) => {
                                                                            this.skuAttrInputHandle(e.target.value, i, j)
                                                                        }} value={sku.attr_val} />
                                                                        {
                                                                            i === 0 && (
                                                                                <>
                                                                                    {
                                                                                        sku.attr_img ?
                                                                                            <div className='imgBox' style={{ marginTop: 10, marginBottom: 6 }}>
                                                                                                <div className='imgBoxBtns'>
                                                                                                    <EyeOutlined className='pointer' onClick={() => {
                                                                                                        this.previewImgHandle(sku.attr_img)
                                                                                                    }} />
                                                                                                    {
                                                                                                        isEdit && (
                                                                                                            <DeleteOutlined className='pointer' onClick={() => {
                                                                                                                this.deleteSkuAttrHandle(i, j, 'img')
                                                                                                            }} />
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                                <div className='imgItem' style={{ backgroundImage: `url(${sku.attr_img})` }} ></div>
                                                                                            </div>
                                                                                            : (
                                                                                                <Row style={{ marginTop: 10 }}>
                                                                                                    {
                                                                                                        isEdit && (
                                                                                                            <Upload
                                                                                                                headers={{
                                                                                                                    Authorization: this.token
                                                                                                                }}
                                                                                                                name="pic"
                                                                                                                listType="picture-card"
                                                                                                                className="avatar-uploader"
                                                                                                                showUploadList={false}
                                                                                                                action={uploadPic.url}
                                                                                                                onChange={(info) => {
                                                                                                                    this.handleSkuImgListChange(info, i, j);
                                                                                                                }}
                                                                                                            >
                                                                                                                <div>
                                                                                                                    {loading ? <LoadingOutlined /> : <PlusOutlined />}
                                                                                                                    <div
                                                                                                                        style={{
                                                                                                                            marginTop: 8,
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        上传
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </Upload>
                                                                                                        )
                                                                                                    }
                                                                                                </Row>
                                                                                            )
                                                                                    }
                                                                                    <div style={{ fontSize: 10 }}>建议尺寸：800 x 800像素</div>
                                                                                </>
                                                                            )
                                                                        }

                                                                    </Item>
                                                                </Col>
                                                            ))
                                                        }

                                                        {
                                                            isEdit && (
                                                                <Col span={3} style={{ border: '1px dashed #2D7CFF', height: i === 0 ? 214 : 62 }}>

                                                                    <div className='addSkuBtnBox' style={{ color: '#2D7CFF' }} onClick={() => { this.addAttrHandle(i); }}>
                                                                        <PlusCircleOutlined style={{ marginRight: 6 }} />
                                                                        添加属性值
                                                                    </div>
                                                                </Col>
                                                            )
                                                        }
                                                    </Row>
                                                </React.Fragment>
                                            ))
                                        }

                                    </Col>
                                </Row>
                            </Form>
                        )
                    }

                    {/* 第三页 */}
                    {
                        currentIndex === 2 && (
                            <Form layout="vertical" ref={skuStockFormRef}>
                                {
                                    skuList.map((sku, i) => (
                                        <Row key={i}>
                                            <Col span={24} className={`groupTitle ${!sku.sku_num ? 'warning' : ''}`}>
                                                {`sku${i + 1}: ${sku.attr_name}`}
                                                {!sku.sku_num && (
                                                    <span style={{ color: '#f00', fontWeight: 'bold' }}>新增项</span>
                                                )}
                                            </Col>
                                            <Col span={24}>
                                                <Row>
                                                    <Col span={11}>
                                                        <Item label='sku编码'>
                                                            <Input value={sku.sku_num || ''} disabled />
                                                        </Item>
                                                    </Col>
                                                    <Col span={11} offset={1}>
                                                        <Item label='商品库存' required>
                                                            <Input value={sku.size} type='number' disabled={!isEdit} placeholder='请输入' onChange={(e) => this.skuChangeHandle(e.target.value, i, 'size')} />
                                                        </Item>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col span={11}>
                                                        <Item label='商品销售价' required>
                                                            <Input value={sku.price} type='number' disabled={!isEdit} placeholder='请输入' onChange={(e) => this.skuChangeHandle(e.target.value, i, 'price')} />
                                                        </Item>
                                                    </Col>
                                                    <Col span={11} offset={1}>
                                                        <Item label='商品成本价'>
                                                            <Input value={sku.cost_price} type='number' disabled={!isEdit} placeholder='请输入' onChange={(e) => this.skuChangeHandle(e.target.value, i, 'cost_price')} />
                                                        </Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    ))
                                }
                            </Form>

                        )
                    }
                </>
            </Modal >

            <Modal open={previewImgVisitble}
                mask={true}
                width={600}
                wrapClassName="previewImgModal"
                className='previewImgModal'
                footer={null}
                style={{ background: 'transparent' }}
                maskClosable={true}
                closable={false}
                destroyOnClose={true}
                onCancel={() => {
                    this.setState({
                        previewImgVisitble: false
                    })
                }}
            >
                <div className='previewModalContent'>
                    <img className='previewImg' src={previewImg} />
                </div>
            </Modal>

            <Modal open={skuModalVisible}
                mask={true}
                title={currentGoodInfo.title}
                width={600}
                maskClosable={true}
                closable={false}
                destroyOnClose={true}
                onOk={() => {
                    this.setState({
                        skuModalVisible: false
                    })
                }}
                onCancel={() => {
                    this.setState({
                        skuModalVisible: false
                    })
                }}
            >
                <Table columns={skuColumns} dataSource={skuViewData || []} scroll={{
                    y: 300,
                }} pagination={{
                    defaultCurrent: 1,
                    pageSize: skuViewData.length,
                    hideOnSinglePage: true,
                }} rowKey={item => item.id} />
            </Modal>
        </>
    }
}

export default Product;