import React from 'react';
import { Routes, Route, Navigate, Link } from 'react-router-dom'
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UploadOutlined,
    UserOutlined,
    LockOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons';
import Category from './pages/category';
import Opinion from './pages/opinion';
import Order from './pages/order';
import Product from './pages/product';

import Login from './pages/login';
import { Layout, Menu, Button, theme, Row, Col} from 'antd';
const { Header, Sider, Content } = Layout;
const {
    colorBgContainer,
    borderRadiusLG,
} = theme.getDesignToken();
class App extends React.Component {
    state = {
        collapsed: false,
        selectedKey: ['product']
    }

    componentDidMount() {
        this.setState({
            isLogin: !!localStorage.getItem('token')
        });

        const key = window.location.hash.split('#/')[1];
        this.clickMenuItemHandle({ key })
    }

    clickMenuItemHandle({ key, keyPath }) {
        this.setState({
            selectedKey: [key]
        });
    }

    menuOptions = {
        theme: "dark",
        style: {
            backgroundColor: '#000c18'
        },
        mode: "inline",
        onSelect: this.clickMenuItemHandle.bind(this),
        items:
            [
                {
                    key: 'product',
                    icon: <VideoCameraOutlined />,
                    label: <Link to="/">商品管理</Link>,

                },
                {
                    key: 'category',
                    icon: <UserOutlined />,
                    label: <Link to="category">商品类目</Link>,

                },

                {
                    key: 'order',
                    icon: <UploadOutlined />,
                    label: <Link to="order">订单管理</Link>,

                },
                {
                    key: 'opinion',
                    icon: <UploadOutlined />,
                    label: <Link to="opinion">意见列表</Link>,
                },
            ]
    }

    render() {
        const {
            selectedKey,
            collapsed,
            isLogin
        } = this.state;
        return (
            <>
                {
                    !isLogin ? (
                        <Login />
                    ) : (
                        <Layout>
                            <Sider trigger={null} theme={'dark'} style={{
                                height: '100vh',
                                paddingTop: '50px'
                            }} collapsible collapsed={collapsed}>
                                <div className="demo-logo-vertical" />
                                <Menu {...this.menuOptions} selectedKeys={selectedKey} />
                            </Sider>
                            <Layout>
                                <Header
                                    style={{
                                        padding: 0
                                    }}
                                >
                                    <Row justify='space-between'>
                                        <Col>
                                            <Button
                                                type="text"
                                                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                                onClick={() => this.setState({
                                                    collapsed: !collapsed
                                                })}
                                                style={{
                                                    fontSize: '16px',
                                                    width: 64,
                                                    height: 64,
                                                }}
                                            />
                                            本地商城管理后台
                                        </Col>
                                        <Col style={{ paddingRight: 20 }}>
                                            A80000 | 老王
                                        </Col>
                                    </Row>
                                </Header>
                                <Content
                                    style={{
                                        margin: '10px 16px 24px',
                                        padding: 24,
                                        minHeight: 280,
                                        background: colorBgContainer,
                                        borderRadius: borderRadiusLG,
                                    }}
                                >

                                    <Routes>
                                        <Route path="/" element={<Product />} />
                                        <Route path="order" element={<Order />} />
                                        <Route path="category" element={<Category />} />
                                        <Route path="opinion" element={<Opinion />} />
                                        <Route path="*" element={<Navigate to="/" replace />} />


                                    </Routes>
                                </Content>

                                {/* <Footer>footer</Footer> */}
                            </Layout>

                        </Layout >
                    )
                }


            </>
        );

    }
};
export default App;